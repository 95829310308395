import React, { useState, useContext } from "react";
import {
  Grommet, grommet, Page, PageContent, PageHeader, Footer, Text, Box,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Grid,
  Heading,
  Paragraph,
  Image,
  Anchor,
  Stack,
  ResponsiveContext,
  Menu
} from 'grommet';
import { Achievement, BusinessService, Calendar, Capacity, Catalog, CloudSoftware,Cluster, Code, Compliance, Cubes, Directions, Plan, Grid as GridIcon, Inherit, Integration, LineChart, Organization, PieChart, Resources, Schedules} from 'grommet-icons';
import AppBar from './Components/AppBar';
import HomeCard from "./Components/HomeCards";
import { deepMerge } from "grommet/utils";
import MarketChart from "./Components/MarketChart";


function App() {
  const [dark, setDark] = useState(false);
  const organizationName = "JH Strategic Enterprises LLC"
  const organizationTagline = "Unlocking the possible"
  const size = useContext(ResponsiveContext);

  const theme = deepMerge(grommet, {
    global: {
      colors: {
        brand: '#1b3159',
        focus: '#246B77',
        "accent-1": '#EAECE7',
        "accent-2": '#778394',
        "accent-3": '#626083',
        "accent-4": '#97A6A9',
        "graph-0":"#3D3C38",
        "graph-1":"#836A4F",
        "graph-2":"#B69963",
        "graph-3":"#B2C2C9",
      },
      font: {
        //family: "Roboto",
        size: "18px",
        height: "20px",
      },
    },
  });

  return (
    <Grommet theme={theme} full={true} themeMode={dark ? "dark" : "light"}>
      <Page>
        <AppBar organizationName={organizationName} dark={dark} setDark={setDark} />
        <Box overflow={"hidden"} height={{ max: 'large' }}>
          <Stack anchor="bottom-left">
            <Image fill="horizontal" src="./webhero_citysun.jpg" />
            <Box fill="horizontal" overflow={"hidden"} pad={{ left: "medium", right: "small", vertical: "small" }} background={{ color: "black", opacity: "medium" }} >
              {/*<Text size="3.5vw" color={"#FFFFFF"} weight="bold" wordBreak="keep-all" >Consulting</Text>*/}
              <Text size="6vw" color={"#FFFFFF"} wordBreak="keep-all">Unleashing the Possible</Text>
              <Text size="3vw" color={"#FFFFFF"} wordBreak="keep-all">We help you achieve the highest value for your organization</Text>
            </Box>
          </Stack>
        </Box>
        <PageContent>
          <Heading>Practices</Heading>
          <Grid columns="medium" gap="large" pad={{ bottom: "large" }}>
            <HomeCard title="Organizational Strategy" icon={<Achievement size="large" />}>
              Develop plans to achieve organizational goals and objectives, guiding decision-making and resource allocation.
            </HomeCard>
            <HomeCard title="Change Management" icon={<Integration size="large"/>}>
              Plan, implement, and monitor changes within an organization in a structured and systematic way to minimize disruption and maximize benefits.
            </HomeCard>
            <HomeCard title="Digital Strategy" icon={<CloudSoftware size="large"/>}>
             Your company's digital technology usage is critical to its ongoing success. Realizing where you are at, where you are headed and developing a roadmap to bring you where you want to be.
            </HomeCard>
            <HomeCard title="Business Process Management" icon={<BusinessService size="large"/>}>
              Follow a systematic approach to identify, model, analyze, and improve an organization's business processes to achieve efficiency, agility, and competitiveness.
            </HomeCard>
            <HomeCard title="Project Management" icon={<Schedules size="large"/>}>
              Apply knowledge, skills, tools, and techniques to plan, execute, monitor, and control a project from start to finish, to achieve its goals and objectives.
            </HomeCard>
            <HomeCard title="Operations Management" icon={<Plan size="large"/>}>
              Design, implement, and control the production and delivery of goods and services to meet customer demand efficiently and effectively
            </HomeCard>
            <HomeCard title="Technology Transformation" icon={<Code size="large"/>}>
              Modernize and upgrade an organization's technology infrastructure, applications, and services to increase efficiency, effectiveness, and agility in response to changing business needs.
            </HomeCard>
            <HomeCard title="Technology Investment" icon={<Cluster size="large"/>}>
              Allocate financial resources to acquire, develop, or improve technology assets that support the strategic goals and objectives of an organization
            </HomeCard>
            <HomeCard title="Technology Management" icon={<Capacity size="large"/>}>
              Develop people and processes for planning, organizing, and controlling the resources and activities that support the development, deployment, and maintenance of technology assets to achieve business objectives.
            </HomeCard>
          </Grid>
          {/*<MarketChart />*/}
        </PageContent>
      </Page>
      <Footer background="brand" pad="medium" flex={false}>
        <Text>Copyright JHSE {new Date().getFullYear()}</Text>
      </Footer>
    </Grommet>
  );
}

export default App;
