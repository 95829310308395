import { Header, Text, Image, Box, ThemeContext, Anchor, Nav, ResponsiveContext, Menu } from 'grommet';
import { Menu as MenuIcon } from "grommet-icons";
const AppBar = (props) => {
  const dark = props.dark;
  const setDark = props.setDark;

  return (
    <Header
      background="brand"
      pad={{ left: "medium", right: "small", vertical: "small" }}
      elevation="medium"
      direction="row"
      justify="between"
      //alignSelf="center"
      alignContent="start"
      gap="medium"
      {...props}
    >
      <ThemeContext.Extend
        value={
          {
            text: {
              font: {
                family: "Abril Fatface",
                size: "20px",
              }
            }
          }
        }
      >
        <Box direction='row' pad={{ left: "small" }} gap="medium">
          <Image src='./JHSELogoWhite.png' height={75} width={75} />
          <Text size="large" alignSelf='center'>{props.organizationName}</Text>
        </Box>
      </ThemeContext.Extend>
      <Nav
        margin={{ left: "medium" }}
        round="xsmall"
        background={{ color: "white", opacity: "weak" }}
        direction="row"
        align="center"
        pad={{ horizontal: "small" }}
      >
        <ResponsiveContext.Consumer>
          {
            responsive=>
            responsive === "small" ? (
            <Menu
              icon={<MenuIcon/>}
              items={[
                {label:"Contact", href:"https://form.feathery.io/to/bOBcUZ"}]}
              />) : 
            (
            <Box direction='row'>
              <Anchor href="https://form.feathery.io/to/bOBcUZ" label="Contact" margin="small" />
            </Box>
            )
          }
          
        </ResponsiveContext.Consumer>


      </Nav>
    </Header>
  );
}

export default AppBar;