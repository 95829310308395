import React,{useContext} from 'react';
import { Grommet, Button, Text, Box,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Grid,
  Heading,
  Paragraph,
  ResponsiveContext,
 } from 'grommet';
 import { Achievement } from 'grommet-icons';

 const HomeCard = (props) => {
    const size = useContext(ResponsiveContext);
      return (
        <Card>
          <Box pad={{top:"small"}} align='center'>{props.icon}</Box>
          <CardHeader justify='center' pad={{left:"medium", right:"medium", top:"small",bottom:"small"}}>
            <Heading level={2} margin="none">
              {props.title}
            </Heading>
          </CardHeader>
          <CardBody pad={{left:"medium", right:"medium"}} align='start' >
            <Paragraph maxLines={size === "small" ? 3 : undefined}>
              {props.children}
            </Paragraph>
          </CardBody>
          <CardFooter pad="medium" background="background-contrast">
            
          </CardFooter>
        </Card>
      );
    };

export default HomeCard